/**
 * File to serve all function utilities miscelaneous
 *
 * Import:
 * import db from 'utilities/utilities.jsx'
 *
 */

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import { jsPDF } from "jspdf"


//import klarlogo from "assets/img/klarlogo.jpg"
//import siglologo from "assets/img/logosiglo.jpg"
import noPhoto from "assets/img/nophoto.png"

import headerkw from "assets/img/headerkw.png"
import footerkw from "assets/img/footerkw.png"

import moment from "moment"
moment.locale("es")
const languages = require('../assets/translate/translate_utilities.json')
let lang = localStorage.lang ? localStorage.lang : 'es'
 
const _anomaliesList = [
  { text: languages[lang].utilities_anotherPersonWasRecognized },
  { text: languages[lang].utilities_lookedAway },
  { text: languages[lang].utilities_moreThanOnePersonWasRecognized },
  { text: languages[lang].utilities_anAlterationInTheEnvironmentIsDetected },
  { text: languages[lang].utilities_tooMuchNoiseInTheEnvironment },
  { text: languages[lang].utilities_lookedDown },
  { text: languages[lang].utilities_lookedUp },
  { text: languages[lang].utilities_lookedToTheSide },
  { text: languages[lang].utilities_youDontHaveEnoughBatteryLevel },
  { text: languages[lang].utilities_activeVirtualMachinesAreFound },
  { text: languages[lang].utilities_youDontHaveEnoughStorageSpace },
  { text: languages[lang].utilities_openProcesses },
  { text: languages[lang].utilities_moreThanOneMonitorIsConnected },
  { text: languages[lang].utilities_theMicrophoneVolumeIsTooLow },
  { text: languages[lang].utilities_imageReplacingCamera},
  { text: languages[lang].utilities_otherToolsAreDetectedInTheForeground },
  { text: languages[lang].utilities_suspiciousKeyboardUsageDetected },
  { text: languages[lang].utilities_thePersonIsNot },
  { text: languages[lang].utilities_theRegisteredPersonIsNotIdentified },
  { text: languages[lang].utilities_faceMovementsAreDetected },
  { text: languages[lang].utilities_recognizeMoreThanOnePerson }

]

const _results = 4
const _validations = 4
const _anomalies = _anomaliesList.length

 
function elEnter(evt,callback) {
  if (evt.charCode === 13) {
    callback()
  }
}


function getCardsModel() {

  let _model = {}

  _model.totalAnomalies = 0

  let counters = []
  for (let i = 0; i < _anomalies; i++) {
    counters.push({ key: i, text: _anomaliesList[i].text, quantity: 0, percent: 0 })
  }
  _model.counters = counters

  let validations = []
  for (let i = 0; i < _validations; i++) {
    validations.push({ key: i, quantity: 0 })
  }
  _model.validations = validations

  //Fill results
  let results = []
  for (let i = 0; i < _results; i++) {
    results.push({ key: i, quantity: 0 })
  }
  _model.results = results
  return _model

}

function fillCardsModel(data) {

  let _model = {}

  let _emptymodel = getCardsModel()

  _model.totalAnomalies = 0

  if (data.counters[_anomalies] === 0) {
    _model.counters = _emptymodel.counters
  } else {
    //Fill counters
    let _percentGral = 100 / data.counters[_anomalies]

    _model.totalAnomalies = data.counters[_anomalies]

    let counters = []

    for (let i = 0; i < _anomalies; i++) {
    
      counters.push({ key: i, text: _anomaliesList[i].text, quantity: data.counters[i], percent: data.counters[i] * _percentGral })
    }
    counters = counters.sort((a, b) => b.percent - a.percent)
    for (let i = 0; i < 14; i++) {
      counters[i].key = i
      counters[i].percent = parseFloat(counters[i].percent).toFixed(0) + '%'
    }
    _model.counters = counters

  }

  //Fill validations

  let validations = []

  for (let i = 0; i < _validations; i++) {
    validations.push({ key: i, quantity: data.validations[i] })
  }
  _model.validations = validations

  //Fill results
  let results = []
  results.push({ key: 0, quantity: data.finalResultAuditory[1] })
  results.push({ key: 1, quantity: data.finalResultAuditory[0] })
  results.push({ key: 2, quantity: data.finalResultAuditory[2] })
  results.push({ key: 3, quantity: data.finalResultAuditory[3] })

  _model.results = results

  return _model

}

function getTitle(cardSelected) {

  const _tableTitleList = [
    { text: languages[lang].utilities_examsWithLowValidation }, // 1
    { text: languages[lang].utilities_examsWithMediumValidation },// 2
    { text: languages[lang].utilities_examsWithHighValidation },// 3
    { text: languages[lang].utilities_examsWithCriticValidation },// 4
    { text: languages[lang].utilities_auditedExamsWithRequestForSanction },// 6
    { text: languages[lang].utilities_validAuditedExams },// 5
    { text: languages[lang].utilities_auditedExamsPostponed },// 7
    { text: languages[lang].utilities_auditedExamsWithCallsForAttention },// 8
    { text: languages[lang].utilities_detectedAnomalies },// 9
  ]

  return _tableTitleList[cardSelected - 1].text

}

function getValidationString(veKey) {
  switch (veKey) {
    case 1:
      return languages[lang].utilities_low
    case 2:
      return languages[lang].utilities_medium
    case 3:
      return languages[lang].utilities_high
    case 4:
      return languages[lang].utilities_critic
    default:
      return languages[lang].utilities_withoutValidation
  }
}

function getValidationInteger(veString) {
  switch (veString) {
    case "BAJA":
      return 1
    case "MEDIA":
      return 2
    case "ALTA":
      return 3
    case "CRITICA":
      return 4
    default:
      return -1
  }
}

function getFinalResultString(resultKey) {
  switch (resultKey) {
    case 0:
      return languages[lang].utilities_withRequestForSanction
    case 1:
      return languages[lang].utilities_validated
    case 2:
      return languages[lang].utilities_deferred
    case 3:
      return languages[lang].utilities_callOfAttention
    default:
      return languages[lang].utilities_unaudited
  }
}

function getFinalResultInteger(resultString) {
  switch (resultString) {
    case 'Con solic. de sanción':
      return 0
    case 'Validado':
      return 1
    case 'Aplazado':
      return 2
    case 'Llamado de atención':
      return 3
    default:
      return null
  }
}

function getValidationDbString(validationKey) {
  switch (validationKey) {
    case 0:
      return languages[lang].utilities_withoutValidation1
    case 1:
      return languages[lang].utilities_low1
    case 2:
      return languages[lang].utilities_medium1
    case 3:
      return languages[lang].utilities_high1
    case 4:
      return languages[lang].utilities_critic1
    default:
      return languages[lang].utilities_n
  }
}

function getValidationDbInteger(validationString) {
  switch (validationString) {
    case "Sin validación":
      return 6
    case "Baja":
      return 1
    case "Media":
      return 2
    case "Alta":
      return 3
    case "Crítica":
      return 4
    case "Sin evidencia":
      case 5:
      return 5
    default:
      return -1
  }
}

function getIncidencesCounterExman(data,incidencesCount) {
    let _model = {}
  
  
    if (incidencesCount === 0 || data.length === 0) {
      _model.counters = []
      return _model
    }
  
    //Fill counters
    let _percentGral = 100 / incidencesCount
  
    var _data = JSON.parse(data)
  
    let counters = []
    let countersFinal = []
  
    for (let i = 0; i < _anomalies; i++) {
      let _cant = (_data.incidencesCounters[i] === null || _data.incidencesCounters[i] === undefined) ? 0 : _data.incidencesCounters[i]
      counters.push({ key: i, _key: i, text: _anomaliesList[i].text, quantity: _cant, percent: _cant * _percentGral })
    }
  
    counters = counters.sort((a, b) => b.percent - a.percent)
  
    for (let i = 0; i < 17; i++) {
      counters[i].key = i
      if (counters[i].percent !== 0) {
        counters[i].percent = parseFloat(counters[i].percent).toFixed(2) + '%'
        countersFinal.push({ key: i, _key:counters[i]._key, text: counters[i].text, quantity: counters[i].quantity, percent: counters[i].percent })
      }
    }
  
    _model.counters = countersFinal
  
    //Fill validations
  
    
    let validations = []
  
    for (let i = 0; i > _validations; i++) {
      validations.push({ key: i, quantity: _data.validations[i] })
    }
    _model.validations = validations
  
    //Fill results
    let results = []
    results.push({ key: 0, quantity: 0 })
    results.push({ key: 1, quantity: 0 })
    results.push({ key: 2, quantity: 0 })
  
    _model.results = results
   
    return _model
  
  }
  
  
  function exportExamnToPdf(data,uni,funcEnd) {
 
      getBase64FromUrl(data.instantPhoto, (imgExamn) => {

        data.instantPhoto64 =  data.instantPhoto&&data.instantPhoto.length>0? imgExamn:""
        getBase64FromUrl(data.userData?JSON.parse(data.userData).i0.i:'', (imgProfile) => {

          data.imgProfile64 = imgProfile
          _exportExamnToPdf(data,uni,funcEnd)
        })
       })
   
  }
  
  export default {
    getCardsModel,
    fillCardsModel,
    getTitle,
    getValidationString,
    getValidationInteger,
    getFinalResultString,
    getFinalResultInteger,
    getValidationDbString,
    getValidationDbInteger,
    getIncidencesCounterExman,
    exportExamnToPdf,
    elEnter,
    checkIfSiglo,
  }
  
  function getBase64FromUrl(url, fncReturn) {
  
    if (url === "") {
      fncReturn("")
return true
    }
  
    var xhr = new XMLHttpRequest()
    xhr.open('get', url)
    xhr.responseType = 'blob'
  
    xhr.onload = function () {
      var fr = new FileReader()
      fr.onload = async function () {
        fncReturn(this.result)
      }
  
      fr.readAsDataURL(xhr.response)
  
    }
  
    xhr.send()
  }
  
  function getImageDimensions(file) {
    return new Promise(function (resolved, rejected) {
      var i = new Image()
      i.onload = function () {
        resolved({ w: i.width, h: i.height })
      }
      i.src = file
    })
  }
  
  function getDeviceInfo(data) {
    let _deviceInfo = JSON.parse(data.systemReport ? data.systemReport : "{}")
  
    let _cpu = _deviceInfo.cpu ? _deviceInfo.cpu.brand ? _deviceInfo.cpu.brand : "" : ""
    let _cores = _deviceInfo.cpu ? _deviceInfo.cpu.cores ? _deviceInfo.cpu.cores : 1 : 1
    let _physicalCores = _deviceInfo.cpu ? _deviceInfo.cpu.physicalCores ? _deviceInfo.cpu.physicalCores : 1 : 1
  
    let _speed = _deviceInfo.cpu ? _deviceInfo.cpu.speed ? _deviceInfo.cpu.speed : 1 : 1
  
    let _mem = _deviceInfo.mem ? _deviceInfo.mem.total ? _deviceInfo.mem.total : 1048576 : 1048576
  
    if (_cpu === "") {
      return languages[lang].utilities_thereIsNoInformation
    }
  
    _mem = Math.trunc(_mem / 1024 / 1024) + languages[lang].utilities_mbRam
  
    //Mhz
    return "" + _cpu + " " + _cores + languages[lang].utilities_nuclei + _physicalCores + languages[lang].utilities_physicalTo + _speed + languages[lang].utilities_mhzTo + _mem
  
  }

  function getInternetInfo(data) {
  
    let _fin = data.upload_end ? " " + parseFloat(data.upload_end.replace(' MBps','')).toFixed(2) + " MBps"  : " -"  
    let _inicio =  data.upload_init ? " " + parseFloat(data.upload_init.replace(' MBps','')).toFixed(2) + " MBps"  : " -"
    let _promedio = data.upload_init && data.upload_end ? " " + ((parseFloat(data.upload_init.replace(' MBps','')) + parseFloat(data.upload_end.replace(' MBps','')) ) / 2).toFixed(2)  + " MBps": " -" 
  
    //Mhz
    return   "Velocidad al inicio del examen: " + _inicio + "\n" + "Velocidad al final del examen: " + _fin + "\n" + "Promedio de velocidad: " + _promedio
  
  }

  async function checkIfSiglo() {

    const response = await fetch(global.Direction_Back_panel + "/v2/checkWorldClass", { method: "Post" })
    let j

    if (response.ok) {
      let json = await response.json();
      j = json.success
      let cond=false
      if(j+''==='1'){
        cond=true
      } 

      localStorage.esSiglo=cond
    }

  }
  
  async function _exportExamnToPdf(data, uni, fncReturn) {
      const exam_table_fields = global.exam_table_fields
      const doc = new jsPDF()
    
      let incidencesCounters = []
    
      if (data.incidencesCounters !== null) {
        incidencesCounters = JSON.parse(data.incidencesCounters)
      } else {
        incidencesCounters = JSON.parse('{"criticCounters": {"1": 0, "2": 0, "3": 0, "4": 0}, "incidencesCounters": {"0": 0, "1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0, "7": 0, "8": 0, "9": 0, "10": 0, "11": 0, "12": 0, "13": 0, "14": 0, "15": 0, "16": 0, "17": 0, "18": 0, "19": 0, "20": 0}}')
      }


      doc.addImage(headerkw, 'PNG', 0, 0, 210, 0)



    
      // doc.setFont("helvetica", "bold")
      // doc.setFontSize(21)
      // doc.text(languages[lang].utilities_virtualExamReport, 7, 24)
    
    
    
      // doc.setFillColor(220, 220, 220)
      // doc.rect(5, 31, 200, 1, 'F')
    
      //Inicio Resumen
      //Left, Top, Widht, Height
    
      doc.setFont("helvetica", "bold")
      doc.setFontSize(14)
      doc.text("RESUMEN DE MONITOREO", 24, 41)
    
      //Recuadro Imagen
      // doc.setFillColor(220, 220, 220);
      // doc.rect(115, 45, 85, 52, 'F');
      doc.setFillColor(220, 220, 220);
      doc.rect(100, 45, 85, 52, 'F');
    
      //Imagen
      // if (data.imgProfile64 === "") {
      //   doc.addImage(noPhoto, 'PNG', 150, 60, 20, 20)
      // } else {
      //   var dimenProfile = await getImageDimensions(data.imgProfile64)
      //   var coordsProfile = calculateAll(dimenProfile,85,52,117, 45)
      //   // photo, rectWidth, rectHeight, coordLeft, coordTop
      //                 //  85,        52,       117,       134
      //   doc.addImage(data.imgProfile64, 'JPEG', coordsProfile.left, coordsProfile.top, coordsProfile.widht, coordsProfile.height)
      // }
      if (data.imgProfile64 === "") {
        doc.addImage(noPhoto, 'PNG', 134, 60, 20, 20)
      } else {
        var dimenProfile = await getImageDimensions(data.imgProfile64)
        var coordsProfile = calculateAll(dimenProfile,85,52,100, 45)
        // photo, rectWidth, rectHeight, coordLeft, coordTop
                      //  85,        52,       117,       134
        doc.addImage(data.imgProfile64, 'JPEG', coordsProfile.left, coordsProfile.top, coordsProfile.widht, coordsProfile.height)
      }
    
      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_nameLastName, 24, 55)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(data.userName, 68, 55)
    
     if (exam_table_fields.file) {
      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_file, 24, 62)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(data.file, 43, 62)
     }
      
    
      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_user, 24, 69)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(data.user, 45, 69)
    
      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text(global.dni_name+':', 24, 76)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(data.dni+'', 33, 76)
    
      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_subject, 24, 90)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(data.subjectName, 45, 90)

      if(exam_table_fields.examType){
      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_typeOfExam, 24, 97)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(data.examType||'', 60, 97)
    }
    
      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_date||'', 24, 104)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(data.examStartWithTime||'', 40, 104)
    
      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_duration+':', 24, 111)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(data.duration||'', 48, 111)
    

      if (exam_table_fields.qualification ) {
        doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text(global._uni_ === "1" ? languages[lang].utilities_provisionalMark : "CALIFICACIÓN:" , 24, 118)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      var qualification =data.qualification? data.qualification.toString():''
      var grade = data.grade ? data.grade.toString() : ""
      doc.text(qualification||'', 67, 118)
    }
      if (exam_table_fields.grade ) {
    
      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_finalMark, 24, 125)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(grade||'', 51, 125)
      }
    
      doc.setFont("helvetica", "normal")
      doc.setFontSize(9)
      doc.text(languages[lang].utilities_imageOfTheCensus, 100, 101)
    
      if (uni !== "1" && uni!=='ST') {
    
        doc.setFont("helvetica", "bold")
        doc.setFontSize(11)
        doc.text(languages[lang].utilities_examValidation, 24, 174)
        doc.setFont("helvetica", "normal")
        doc.setFontSize(11)
        doc.text(getValidationDbString(data.validation)||'', 77, 174)
    
      }
      /*8doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text('VALIDACIÓN:', 50, 104)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(getValidationDbString(data.validation), 77, 104)*/
    
      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_coincidence, 24, 147)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      // var confidence = data.confidence ? data.confidence.toString() : "-"
      doc.text(languages[lang].utilities_yes1, 56, 147)
    
      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_identityValidation, 24, 153)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_yes, 80, 153)
    
      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_environmentValidation, 24, 159)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_yes, 81, 159)
    
      // if (esSiglo) {
      //   doc.setFont("helvetica", "bold")
      //   doc.setFontSize(11)
      //   doc.text('VALIDACIÓN DEL EXAMEN:', 7, 175)
    
      // }
      /*doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text('ANOMALÍAS:', 50, 111)*/
    
      let _bajas = incidencesCounters.criticCounters[1] ? incidencesCounters.criticCounters[1] : 0
      let _medias = incidencesCounters.criticCounters[2] ? incidencesCounters.criticCounters[2] : 0
      let _altas = incidencesCounters.criticCounters[3] ? incidencesCounters.criticCounters[3] : 0
      let _criticas = incidencesCounters.criticCounters[4] ? incidencesCounters.criticCounters[4] : 0
    
    
    
      /*doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text('BAJAS:', 76, 111)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(_bajas.toString(), 90, 111)*/
    
      if (uni !== "1" && uni!=='ST') {
        doc.setFont("helvetica", "bold")
        doc.setFontSize(11)
        doc.text(languages[lang].utilities_anomalies, 24, 180)
        doc.setFont("helvetica", "normal")
        doc.setFontSize(11)
        doc.text(languages[lang].utilities_low2, 24, 185)
        doc.setFont("helvetica", "normal")
        doc.setFontSize(11)
        doc.text(_bajas.toString()||'', 38, 185)
    
    
        doc.setFont("helvetica", "normal")
        doc.setFontSize(11)
        doc.text(languages[lang].utilities_medium2, 44, 185)
        doc.setFont("helvetica", "normal")
        doc.setFontSize(11)
        doc.text(_medias.toString()||'', 61, 185)
    
        doc.setFont("helvetica", "normal")
        doc.setFontSize(11)
        doc.text(languages[lang].utilities_high2, 24, 190)
        doc.setFont("helvetica", "normal")
        doc.setFontSize(11)
        doc.text(_altas.toString()||'', 38, 190)
    
        doc.setFont("helvetica", "normal")
        doc.setFontSize(11)
        doc.text(languages[lang].utilities_critic2, 44, 190)
        doc.setFont("helvetica", "normal")
        doc.setFontSize(11)
        doc.text(_criticas.toString()||'', 64, 190)
      }
    
      //Resultado de auditoria
      //Left, Top, Widht, Height
    
      if (uni !== "1" && uni!=='ST') {
        doc.setFont("helvetica", "bold")
        doc.setFontSize(14)
        doc.text(languages[lang].utilities_auditResult ||''||'', 24, 141)
      }
    
      /*doc.setFont("helvetica", "bold")
      doc.setFontSize(14)
      doc.text('Resultado de auditoría', 7, 133)*/
    
      doc.setFont("helvetica", "normal")
      doc.setFontSize(9)
      doc.text(languages[lang].utilities_imageThatWasUsedDuringTheExam, 100, 190)
    
      //Recuadro Imagen
      doc.setFillColor(220, 220, 220);
      doc.rect(100, 134, 85, 52, 'F');
    
      //Imagen
      if (data.instantPhoto64.length<1 ) {
        doc.addImage(noPhoto, 'PNG', 134, 150, 20, 20)
      } else {
        var dimenInstant = await getImageDimensions(data.instantPhoto64)

        var coordsInstant = calculateAll(dimenInstant, 85, 52, 100, 134)
        doc.addImage(data.instantPhoto64, 'JPEG', coordsInstant.left, coordsInstant.top, coordsInstant.widht, coordsInstant.height)
      }
    
     

    //Resumen de anomalías
      //Left, Top, Widht, Height
      
    let addedHeight=0
      if (uni !== "1" && uni!=='ST') {
        doc.setFont("helvetica", "bold")
        doc.setFontSize(14)
        doc.text(languages[lang].utilities_examSummary, 24, 200)
      
      if (data.incidencesTable.counters.length > 0) {
        //doc.setFontSize(10)
        //doc.text('Porcentaje', 5, 214)
        //doc.setFontSize(10)
        //doc.text('Anomalía', 28, 214)
        for (let i = 0; i < data.incidencesTable.counters.length; i++) {
          var _porc = data.incidencesTable.counters[i].percent.toString()
          addedHeight+=5
          doc.setFont("helvetica", "normal")
          doc.setFontSize(11)
          //doc.text(_porc, 25, 218 + i * 4, { align: "right" })
          doc.text(_porc||'', 24, 210 + i * 5)
          doc.setFont("helvetica", "normal")
          doc.setFontSize(11)
          doc.text(data.incidencesTable.counters[i].text||'', 50, 210 + i * 5)
        }
    
      } else {
        doc.setFont("helvetica", "normal")
        doc.setFontSize(11)
        doc.text(' ', 24, 236)
      }}
    if(addedHeight>20){
      addedHeight-=20
    }
    else{
      addedHeight=0
    }
      //Dispositivo utilizado
      //Left, Top, Widht, Height
      doc.setFont("helvetica", "bold")
      doc.setFontSize(14)
      doc.text(languages[lang].utilities_deviceUsed||'', 24, 236 + addedHeight)
    
      doc.setFont("helvetica", "normal");
      doc.setFontSize(11)
      doc.text(getDeviceInfo(data)||'', 24, 242+ addedHeight)


      //Conexion a Internet
      //Left, Top, Widht, Height
      doc.setFont("helvetica", "bold")
      doc.setFontSize(14)
      doc.text("Conexión a Internet (subida):", 24, 252+ addedHeight)
    
      doc.setFont("helvetica", "normal");
      doc.setFontSize(11)
      doc.text(getInternetInfo(data)||'', 24, 258+ addedHeight)
      


      /*doc.setFont("helvetica", "bold")
      doc.setFontSize(14)
      doc.text('Resumen de anomalías', 7, 226)*/
    
      
    
      // doc.setFillColor(220, 220, 220)
      // doc.rect(5, 284, 200, 1, 'F')
    
      let _printDate = moment().format('DD/MM/YYYY HH:mm:ss')
    
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_poweredByKlarway, 24, 284)
    
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_printingDate + _printDate + ".", 185, 284, { align: "right" })



      doc.addImage(footerkw, 'PNG', 0, 279, 210, 0)


      // NUEVA PAGINA se crea la pagina 2 con nuevos datos

      doc.addPage()

      doc.addImage(headerkw, 'PNG', 0, 0, 210, 0)

      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text("Modalidad: ", 24, 41)
      if(data.finalResultAuditory && !data.auditorUser && data.finalResultAuditory > -1){
        doc.setFont("helvetica", "bold")
        doc.setFontSize(11)
        doc.text("AUTOMATICA", 47, 41)
      }else{
        doc.setFont("helvetica", "bold")
        doc.setFontSize(11)
        doc.text("MANUAL", 47, 41)
      }

      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text("Resultado de auditoría:", 24, 48)
      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)

      let _resOfAudit='PENDIENTE'
      if(data.finalResultAuditory !== -1) {
        _resOfAudit=(data.finalResultAuditory === 1) ? "VÁLIDO" : (data.finalResultAuditory === 2) ? "APLAZADO" : (data.finalResultAuditory === 0) ? "SOLICITUD DE SANCIÓN" : "PENDIENTE"
      }

      let _resOfAuditColor='#FF9635'
      if(data.finalResultAuditory !== -1) {
        _resOfAuditColor=(data.finalResultAuditory === 1) ? '#009CFB' : (data.finalResultAuditory === 2) ? "#DB0000" : (data.finalResultAuditory === 0) ? "#DB0000" : "#FF9635"
      }
      doc.setTextColor(_resOfAuditColor)
      doc.text(_resOfAudit, 70, 48)
      


      doc.setTextColor("#000000")
      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text("Auditor:", 24, 53)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(data.auditorUser ? data.auditorUser: "", 40, 53)

      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text("Duración:", 24, 58)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)

      let _durationAuditory = "";
      if (data.startOfAudit && data.startOfAudit !== null && data.endOfAudit && data.endOfAudit !== null) {
        let _start = moment(data.startOfAudit);
        let _end = moment(data.endOfAudit);
        let _diff = _end.diff(_start);
        _durationAuditory = moment.utc(_diff).format("HH:mm:ss");
      }

      doc.text( _durationAuditory, 44, 58)

      doc.setFont("helvetica", "bold")
      doc.setFontSize(11)
      doc.text("Detalle:", 24, 63)
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(data.finalResultAuditoryDetail ? data.finalResultAuditoryDetail : "", 40, 63)
    
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_poweredByKlarway, 24, 284)
    
      doc.setFont("helvetica", "normal")
      doc.setFontSize(11)
      doc.text(languages[lang].utilities_printingDate + _printDate + ".", 185, 284, { align: "right" })
      doc.addImage(footerkw, 'PNG', 0, 279, 210, 0)

    
      //290 mm final
    
      doc.save("Resumen de monitoreo.pdf")
    
      fncReturn()
    
    }

function calculateAll(photo, rectWidth, rectHeight, coordLeft, coordTop) {
  //doc.addImage(data.imgProfile64, 'JPEG', coords.left, coords.top, coords.widht, coords.height)
  //var coords = calculateAll(dimensions, 32, 64, 13, 51)


  let _coords = []
  var prop = 0

  if (photo.w > photo.h) {
    prop = (100 * rectWidth) / photo.w
    prop = prop / 100
    _coords.widht = photo.w * prop
    _coords.height = photo.h * prop
    if (_coords.height > rectHeight) {
      prop = (100 * rectHeight) / photo.h
      prop = prop / 100
      _coords.widht = photo.w * prop
      _coords.height = photo.h * prop
    }
  } else {
    prop = (100 * rectHeight) / photo.h
    prop = prop / 100
    _coords.widht = photo.w * prop
    _coords.height = photo.h * prop
    if (_coords.widht > rectWidth) {
      prop = (100 * rectWidth) / photo.w
      prop = prop / 100
      _coords.widht = photo.w * prop
      _coords.height = photo.h * prop
    }
  }
  //prop = prop / 100
  //_coords.widht = photo.w * prop
  //_coords.height = photo.h * prop
  _coords.left = (rectWidth - _coords.widht) / 2 + coordLeft
  _coords.top = (rectHeight - _coords.height) / 2 + coordTop
  return _coords
}


