/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Avatar, Button, Card, CardHeader, Checkbox, CircularProgress, Container, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, OutlinedInput, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Kw_left_bg from '../../../assets/img/kw-left-bg.jpg';
import ok_icon_big from "assets/img/ok_icon_big.svg"
import info_icon_big from "assets/img/info_icon_big.png"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import KWICON from "../../../assets/img/kw.svg"
import KwSelector from "./kw-selector.svg"
import CanvasSelector from "./canvas-selector.png"
import BrightspaceSelector from "./brightspace-selector.svg"
import MoodleSelector from "./moodle-selector.svg"
import BlackboardSelector from "./blackboard-selector.svg"
import firebase, { auth } from "firebase";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider } from '@mui/material/styles';

import { createTheme } from '@mui/material/styles';
import { LoadingButton } from "@mui/lab";

const theme = createTheme({
  palette: {
    primary: {
      main: '#009EFD', // Change this to your desired color
    },
    // Other palette options...
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#FFFFFF', // Set text color for contained buttons
        },
      },
    },
  },
  // Other theme options...
});

class LoginPage extends React.Component {

  constructor(props) {
    super(props)
    const envprops = localStorage.envprops ? JSON.parse(localStorage.envprops) : {}

    this.state = {
      view: envprops.type_login === 1 ? 1 : 0,
      login_user: localStorage.rememberMe && JSON.parse(localStorage.rememberMe) ? localStorage.r_user : "",
      login_pwd: "",
      see_login_pwd: false,
      forgot_user: "",
      forgot_token: [],
      forgot_pwd_1: "",
      see_forgot_pwd_1: false,
      forgot_pwd_2: "",
      see_forgot_pwd_2: false,
      firstTime: false,
      rememberMe: localStorage.rememberMe && JSON.parse(localStorage.rememberMe) || false,
      showErrorToken: false,
      type_login: envprops.type_login,
      loadingemail: false,
      loadinglogin: false,
      loadingnewpwd: false,
      loadingtoken: false,
    }
    this.recaptchatoken = null
    this.tokenInput0 = React.createRef();
    this.tokenInput1 = React.createRef();
    this.tokenInput2 = React.createRef();
    this.tokenInput3 = React.createRef();
    this.tokenInput4 = React.createRef();

    this.loginUserRef = React.createRef();
    this.loginPwdRef = React.createRef();

    this.forgotUserRef = React.createRef();

    this.forgotPwd1Ref = React.createRef();
    this.forgotPwd2Ref = React.createRef();

  }
  componentDidMount() {
    if (localStorage.rememberMe && JSON.parse(localStorage.rememberMe)) {
      this.vToken()
    }
    if (global.loginType === "3" || global.loginType === "2") {
      try {
        // let code = window.location.href.split('code=')[1]
        let code = global.logincode
        if (code && code.length > 0) {
          let data = new FormData()

          data.append("code", code)
          data.append("uni", global._uni_)
          data.append("reCaptchaToken", this.recaptchatoken)

          let requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: data
          };
          fetch(global.Direction_Back_Login + "/loginuni", requestOptions)
            .then(response => response.text())
            .then(result => {
              let _res = JSON.parse(result)
              if (_res.success) {
                localStorage.token_canvas = _res.token
                this.saveBackToken(_res.token)
                let parsed = this.parseJwt(_res.token)
                // parsed.authorities = ["Biv_User_Admin"]
                this.singinFirebase(_res.token)
              }
              else {
                if (_res.error === 'Students are not allowed.') {
                  // this.showError('Los estudiantes no tienen permiso de usar esta herramienta')
                }
              }
            })
            .catch(error => console.log('error', error));
        }
        else {
          // window.open(global.Direction_LoginOauth, '_self')
        }
      }
      catch (error) {

      }

    }


  }
  vToken() {
    let back_jwt = localStorage.back_jwt
    if (back_jwt) {
      this.singinFirebase(back_jwt, 1)
    }
  }
  saveBackToken(jwt) {
    localStorage.setItem('back_jwt', jwt)
  }
  saveFbToken(jwt) {
    localStorage.setItem('fb_jwt', jwt)
  }
  singinFirebase(sJWT, x) {
    firebase.auth().signInWithCustomToken(sJWT)
      .then((userCredential) => {
        console.log(userCredential)
        const user = userCredential.user;
        localStorage.refreshTokenFB = user.refreshToken
        this.checkuser(sJWT)



      })
      .catch((error) => {
        if (x) {
          return
        }
        this.setState({ errorLogin: true })
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }
  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };
  checkuser(back_jwt) {
    let user = firebase.auth().currentUser
    if (user) {
      firebase.auth().currentUser.getIdToken(true).then((idToken) => {
        // Send token to your backend via HTTPS
        this.saveFbToken(idToken)
        global.loggedIn(back_jwt, idToken, this.parseJwt(back_jwt), '-', () => {
          this.setState({ view: 8 })
        })
      }).catch(function (error) {
        this.setState({ errorLogin: true })
        console.log('error', error)
      });
    }

  }
  refreshPage = () => {
    window.location.reload();
  }
  render() {
    const { classes } = this.props;
    const sendRecoveryMail = () => {
      this.setState({ loadingemail: true })
      let data = new FormData();
      data.append("email", this.state.forgot_user)
      data.append("reCaptchaToken", this.recaptchatoken)
      let requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        redirect: 'follow',
        body: data
      };
      fetch(global.Direction_Back_panel + "/users/sendMailRestorePass", requestOptions)
        .then(response => response.text())
        .then(result => {
          let _res = JSON.parse(result)
          this.setState({ refreshReCaptcha: true })
          if (_res.success) {
            this.setState({ view: 3, loadingemail: false })
          }
          else {
            this.setState({ loadingemail: false })

            // this.showError('Usuario inexistente')
          }
        })
        .catch(error => {
          this.setState({ refreshReCaptcha: true, loadingemail: false })
          let _err = error
          if (_err.code === "USER_NOT_FOUND") {
            // this.showError('Usuario inexistente')
          }
        });


    }
    const changepwd = (ev) => {
      this.setState({ login_pwd: ev.target.value })
    }
    const changeuser = (ev) => {
      this.setState({ login_user: ev.target.value })

    }
    const changeuser_fp = (ev) => {
      this.setState({ forgot_user: ev.target.value })

    }
    const change_recovery_pwd_1 = (ev) => {
      this.setState({ forgot_pwd_1: ev.target.value })

    }
    const change_recovery_pwd_2 = (ev) => {
      this.setState({ forgot_pwd_2: ev.target.value })
    }
    const verifySequrityCode = () => {

      this.setState({ loadingtoken: true })

      let data = new FormData();
      data.append("email", this.state.forgot_user)
      data.append("token", this.state.forgot_token.join(''))
      data.append("reCaptchaToken", this.recaptchatoken)

      let requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow'
      };

      fetch(global.Direction_Back_panel + "/users/verifiedTokenRestore", requestOptions)
        .then(response => response.text())
        .then(result => {
          let _res = JSON.parse(result)
          this.setState({ refreshReCaptcha: true })
          if (_res.success) {
            this.setState({ view: 4, loadingtoken: false })
          }
          else {
            this.setState({ showErrorToken: true, loadingtoken: false })


          }
        })
        .catch(error => {
          // this.setState({ refreshReCaptcha: true })
          this.setState({ showErrorToken: true, loadingtoken: false })
          this.showError('Ocurrió un error, por favor intente de nuevo más tarde')
        });
    }
    const saveNewPwd = () => {
      this.setState({ loadingnewpwd: true })

      let data = new FormData();
      data.append("email", this.state.forgot_user)
      data.append("token", this.state.forgot_token.join(''))
      data.append("password", this.state.forgot_pwd_1)
      data.append("reCaptchaToken", this.recaptchatoken)


      let requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow'
      };

      fetch(global.Direction_Back_panel + "/users/modifyPassword", requestOptions)
        .then(response => response.text())
        .then(result => {
          this.setState({ refreshReCaptcha: true })
          let _res = JSON.parse(result)
          if (_res.success) {
            this.setState({ view: 5 })

            setTimeout(() => {
              this.refreshPage()
            }, 4000)
          }
          else {
            this.setState({ loadingnewpwd: false })

            // this.showError('Ocurrió un error, por favor intente nuevamente más tarde')

          }
        })
        .catch(error => {
          this.setState({ refreshReCaptcha: true, loadingnewpwd: false })
          // this.showError('Ocurrió un error, por favor intente nuevamente más tarde')
        });
    }
    const f_login = () => {
      if (!this.recaptchatoken) {
        this.showError('Rechaptcha invalido.')
        return null
      }
      // if (!this.password1) {
      //   // this.setState({ notPass: true })

      // }
      // if (!this.email1) {
      //   // this.setState({ notEmail: true })
      // }
      // if (!this.email1 || !this.password1) {
      //   return true
      // }
      this.setState({ loadinglogin: true })
      let myHeaders = new Headers();
      let data = new FormData()

      data.append("user", this.state.login_user.split(' ').join(''))
      data.append("password", this.state.login_pwd)
      data.append("reCaptchaToken", this.recaptchatoken)
      data.append("uni", global._uni_)

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: data
      };

      fetch(global.Direction_Back_Login + `/loginKlarway`, requestOptions)
        .then(response => response.text())
        .then(result => {
          let resjson = JSON.parse(result)

          if (resjson.success) {
            localStorage.r_user = this.state.login_user.split(' ').join('')
            var sJWT = resjson.token;
            this.saveBackToken(sJWT)
            this.singinFirebase(sJWT)

          }
          else {
            this.setState({ errorLogin: true, loadinglogin: false })
            //  this.showError('Usuario o contraseña incorrecta.')
          }
        })
        .catch(error => { this.setState({ errorLogin: true }) }
        );

    }
    const f_forgot = () => {
      this.setState({ view: 2 })
    }
    const changetoken_fp = (x, y) => {
      let arr = this.state.forgot_token
      arr[y] = x
      this.setState({ forgot_token: arr, showErrorToken: false })
    }
    const seepwd = (x) => {
      this.setState({ [x]: !this.state[x] })
    }
    const inputToken = { "sx": { padding: "0px 12px", width: "100%", height: "100%", fontSize: "40px" } }
    return (
      <div style={{ width: "100%", height: "100vh" }}>
        <ThemeProvider theme={theme}>
          <Grid container style={{ width: "100%", height: '100%' }}>
            <Grid item xs={6} >

              <div style={{ backgroundImage: `url(${Kw_left_bg})`, backgroundSize: "cover", width: "100%", height: '100%', position: "relative" }}>
                <div style={{
                  // background: "linear-gradient(225deg, rgba(151, 71, 255, 1) 25%, rgba(151, 71, 255, 0) 100%)",
                  posistion: "absolute",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignContent: "center",
                  alignItems: "center"
                }}>
                  <img style={{ width: "225px" }} src={KWICON}></img>
                  <div style={{ width: "225px", display: "flex", justifyContent: "end", cursor: "default" }}>
                    <div style={{ backgroundColor: "#ffffff", borderRadius: "4px", width: "fit-content", padding: "2px 10px", marginTop: "5px" }}>
                      <p style={{ margin: 0, color: "#9747FF", fontFamily: 'DINPro', fontSize: '18px', fontWeight: "700" }}>Manager</p></div></div>
                </div>
              </div>
              {/* <img
              width="100%"
              height="100%"
              src={Kw_left_bg}
              alt="Pantalla de inicio de sesión de Klarway"

            /> */}
            </Grid>
            <Grid item xs={6} sx={this.state.view < 2 ? { backgroundColor: "#FFFFFF", paddingTop: '100px', position: "relative", overflow: "scroll" } :
              { backgroundColor: "#FFFFFF", display: "flex", position: "relative", overflow: "scroll" }}>
              {(this.state.view > (this.state.type_login === 1 ? 1 : 0)) && this.state.view < 5 ?
                <Grid direction="row" container alignItems="center" justifyContent="space-between" style={{ top: "25px", position: "absolute", width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
                  <Grid item >
                    <ArrowBackIcon onClick={() => {
                      this.setState({
                        view: this.state.view - 1,
                        loadingemail: false,
                        loadinglogin: false,
                        loadingnewpwd: false,
                        loadingtoken: false,
                      })
                    }} style={{ width: "25px", cursor: "pointer" }}></ArrowBackIcon>
                  </Grid>
                  <Grid item  >
                    <CloseIcon onClick={() => {
                      this.setState({
                        view: (this.state.type_login === 1 ? 1 : 0),
                        login_user: localStorage.rememberMe && JSON.parse(localStorage.rememberMe) ? localStorage.r_user : "",
                        login_pwd: "",
                        see_login_pwd: false,
                        forgot_user: "",
                        forgot_token: [],
                        forgot_pwd_1: "",
                        see_forgot_pwd_1: false,
                        forgot_pwd_2: "",
                        see_forgot_pwd_2: false,
                        firstTime: false,
                        rememberMe: localStorage.rememberMe && JSON.parse(localStorage.rememberMe) || false,
                        showErrorToken: false,
                        type_login: this.state.type_login,
                        loadingemail: false,
                        loadinglogin: false,
                        loadingnewpwd: false,
                        loadingtoken: false,

                      })
                    }} style={{ width: "25px", cursor: "pointer" }}></CloseIcon>

                  </Grid>

                </Grid>
                : null}

{this.state.view==8 ?
                <Grid direction="row" container alignItems="center" justifyContent="space-between" style={{ top: "25px", position: "absolute", width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
                  <Grid item >
                    <ArrowBackIcon onClick={() => {
                       localStorage.clear();
                       window.location.reload();
                    }} style={{ width: "25px", cursor: "pointer" }}></ArrowBackIcon>
                  </Grid>
                  <Grid item  >
                    <CloseIcon onClick={() => {
                     localStorage.clear();
                     window.location.reload();
                    }} style={{ width: "25px", cursor: "pointer" }}></CloseIcon>

                  </Grid>

                </Grid>
                : null}
              {
                this.state.view === 0 ?
                  <Container maxWidth="sm" style={{ justifyContent: "center", display: "flex", flexDirection: "column", textAlign: "center" }}>
                    <Typography variant="h5" style={{ fontSize: "35px", fontWeight: "500", color: "#333333", fontFamily: "DINPro-Medium", cursor: "default" }}>
                      Selecciona una opción
                    </Typography>
                    <Typography variant="h5" style={{ fontSize: "20px", fontWeight: "400", color: "#333333", fontFamily: "DINPro", cursor: "default" }}>
                      para ingresar a Klarway Manager
                    </Typography>
                    <Card variant="outlined" onClick={() => {
                      this.setState({ view: 1 })
                    }} className="logincards" sx={{ marginTop: '50px', cursor: "pointer", border: "1.5px solid rgba(189, 189, 189, 0.60)" }}>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ bgcolor: 'rgba(0, 158, 253, 0.05)', padding: "8px" }} src={KwSelector} aria-label="recipe">
                            K
                          </Avatar>
                        }
                        action={
                          <IconButton aria-label="settings">
                            <ChevronRightIcon />

                          </IconButton>
                        }
                        titleTypographyProps={{
                          color: "#404040",
                          fontSize: 20,
                          fontFamily: 'DINPro',
                          fontWeight: '700'
                        }}
                        sx={{ textAlign: "left" }}
                        subheaderTypographyProps={{
                          color: '#717171',
                          fontSize: '16px',
                          fontFamily: 'DINPro',
                          fontWeight: '400',
                        }}
                        title="Tengo usuario Klarway"
                        subheader="Ingresa con los accesos que recibiste por mail"
                      />
                    </Card>
                    <Card variant="outlined"
                      onClick={() => {
                        window.open(global.Direction_LoginOauth, '_self')
                        // this.setState({ view: 7 })
                      }}
                      className="logincards" sx={{ marginTop: '25px', cursor: "pointer", border: "1.5px solid rgba(189, 189, 189, 0.60)" }}>
                      <CardHeader
                        avatar={
                          <Avatar sx={{ bgcolor: 'rgba(0, 158, 253, 0.05)', padding: "8px" }} src={(global._lms_ === '6' ? BrightspaceSelector : global._lms_ === '4' ? CanvasSelector : global._lms_ === '3' ? MoodleSelector : global._lms_ === '5' ? BlackboardSelector : global._lms_ === '0' || global._lms_ === '1' ? KwSelector : "LMS")} aria-label="recipe">
                            LMS
                          </Avatar>
                        }
                        action={
                          <IconButton aria-label="settings">
                            <ChevronRightIcon />

                          </IconButton>
                        }
                        titleTypographyProps={{
                          color: "#404040",
                          fontSize: 20,
                          fontFamily: 'DINPro',
                          fontWeight: '700'
                        }}
                        sx={{ textAlign: "left" }}
                        subheaderTypographyProps={{
                          color: '#717171',
                          fontSize: '16px',
                          fontFamily: 'DINPro',
                          fontWeight: '400',
                        }}

                        title={"Tengo usuario " + (global._lms_ === '6' ? "Brigthspace" : global._lms_ === '4' ? 'Canvas' : global._lms_ === '3' ? 'Moodle' : global._lms_ === '5' ? 'Blackboard' : global._lms_ === '0' || global._lms_ === '1' ? 'SIS' : "LMS")}
                        subheader="Accede desde tu plataforma educativa"
                      />
                    </Card>


                  </Container>
                  :
                  this.state.view === 1 ?
                    <Container maxWidth="lg" style={{ justifyContent: "center", display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center" }}>
                      <Container maxWidth="xs" style={{ justifyContent: "center", display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center" }}>
                        <Typography variant="h5" style={{ fontSize: "45px", fontWeight: "500", color: "#101828", fontFamily: "DINPro-medium", cursor: "default" }}>
                          Iniciar sesión
                        </Typography>
                        <div style={{ width: "100%", justifyContent: "center", display: "flex", flexDirection: "column", textAlign: "center", }}>
                          <Typography variant="h5" style={{ fontSize: "16px", fontWeight: "500", color: "#101828", fontFamily: "DINPro-medium", textAlign: "left", marginTop: "25px", cursor: "default" }}>
                            Usuario
                          </Typography>
                          <TextField
                            error={this.state.errorLogin}
                            placeholder="nombre@email.com"
                            inputProps={{ ref: this.loginUserRef }}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter' && this.state.login_user) {
                                if (this.state.login_pwd) {
                                  // Enter key was pressed
                                  f_login()
                                }
                                else {
                                  this.loginPwdRef.current.focus()
                                }
                                // Your logic for handling Enter key press
                              }
                            }}
                            id="outlined-size-small"
                            size="small"
                            value={this.state.login_user}
                            onChange={changeuser}

                          />
                          <Typography variant="h5" style={{ fontSize: "16px", fontWeight: "500", color: "#101828", fontFamily: "DINPro-medium", textAlign: "left", marginTop: "25px", cursor: "default" }}>
                            Contraseña
                          </Typography>
                          <FormControl variant="outlined">

                            <OutlinedInput
                              placeholder="••••••••"
                              id="outlined-size-small"
                              size="small"
                              inputProps={{ ref: this.loginPwdRef }}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter' && this.state.login_user && this.state.login_pwd) {
                                  // Enter key was pressed
                                  f_login()
                                  // Your logic for handling Enter key press
                                }
                              }}
                              error={this.state.errorLogin}

                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => { seepwd('see_login_pwd') }}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {this.state.see_login_pwd ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              type={this.state.see_login_pwd ? "text" : "password"}
                              onChange={changepwd}

                            />
                          </FormControl>

                          <Grid direction="row" container alignItems="center" justifyContent="space-between" style={{ marginTop: "25px", marginBottom: '55px' }}>
                            <Grid item >
                              <FormControlLabel className="formcontrolrememberme" control={<Checkbox
                                style={{ color: 'none' }}
                                checked={this.state.rememberMe}
                                onChange={(event) => {
                                  this.setState({ rememberMe: event.target.checked })
                                  localStorage.rememberMe = JSON.stringify(event.target.checked)
                                }}
                              />} label="Recordarme"
                              />
                            </Grid>
                            <Grid item  >
                              <Button variant="text" size="small" style={{ textTransform: "none", fontSize: "16px", fontFamily: "DINPro-Medium" }}
                                onClick={f_forgot}
                              >Olvidé mi contraseña</Button>
                            </Grid>

                          </Grid>
                          <LoadingButton
                            loading={this.state.loadinglogin}
                            disabled={(!this.state.login_user || !this.state.login_pwd ? true : false)}
                            onClick={f_login}
                            variant="contained" size="large">
                            Ingresar
                          </LoadingButton>
                        </div>


                      </Container>
                      {this.state.errorLogin ?
                        <Paper elevation={0} sx={{ borderRadius: "10px", backgroundColor: "rgba(255, 0, 0, 0.03)", padding: "25px 35px 25px 35px", width: "100%", textAlign: "left", marginTop: '75px' }}>
                          <Typography variant={'h5'} style={{ color: "#F00", fontFamily: "DINPro-bold", fontWeight: "500", fontSize: "15px" }}>¿No puedes ingresar? Revisa los siguientes puntos:</Typography>
                          <Typography variant={'h5'} style={{ color: "#F00", fontFamily: "DINPro", fontWeight: "400", fontSize: "14px", marginTop: "15px" }}>&nbsp;•&nbsp; El email o la contraseña son <b>incorrectos.</b> </Typography>
                          {
                            this.state.type_login === 1 ? null :
                              <Typography variant={'h5'} style={{ color: "#F00", fontFamily: "DINPro", fontWeight: "400", fontSize: "14px" }}>&nbsp;•&nbsp; Si tienes una <b>cuenta en {(global._lms_ === '6' ? 'Brightspace' : global._lms_ === '4' ? 'Canvas' : global._lms_ === '3' ? 'Moodle' : global._lms_ === '5' ? "Blackboard" : global._lms_ === '0' || global._lms_ === '1' ? 'SIS' : "LMS")}</b>, regresa a la pantalla anterior y elige esa opción.</Typography>
                          }
                          <Typography variant={'h5'} style={{ color: "#F00", fontFamily: "DINPro", fontWeight: "400", fontSize: "14px" }}>&nbsp;•&nbsp; <b>Aún no tienes un usuario</b> creado en Klarway Manager, solicítalo a tu institución.</Typography>

                        </Paper>
                        :
                        null}
                    </Container>
                    :
                    this.state.view === 2 ?
                      <Container maxWidth="xs" style={{ justifyContent: "center", display: "flex", flexDirection: "column", textAlign: "center" }}>
                        <Typography variant="h5" style={{ fontSize: "25px", fontWeight: "500", color: "#101828", fontFamily: "DINPro-Medium", cursor: "default" }}>
                          Recuperar contraseña
                        </Typography>
                        <Typography variant="h5" style={{ fontSize: "16px", fontWeight: "500", color: "#101828", fontFamily: "DINPro-Medium", textAlign: "left", marginTop: "25px", cursor: "default" }}>
                          Email
                        </Typography>
                        <TextField
                          placeholder="nombre@email.com"
                          id="outlined-size-small"
                          size="small"
                          inputProps={{ ref: this.forgotUserRef }}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter' && !(this.state.forgot_user.length < 1)) {
                              // Enter key was pressed
                              sendRecoveryMail()
                              // Your logic for handling Enter key press
                            }
                          }}
                          value={this.state.forgot_user}

                          onChange={changeuser_fp}

                        />
                        <LoadingButton
                          loading={this.state.loadingemail}
                          onClick={sendRecoveryMail} disabled={this.state.forgot_user.length < 1} variant="contained" size="large" style={{ marginTop: "50px" }}>
                          Confirmar
                        </LoadingButton>
                        <Button onClick={() => { this.setState({ view: this.state.view - 1 }) }} variant="text" size="large" style={{ marginTop: "15px" }}>
                          Cancelar
                        </Button>
                      </Container>
                      :
                      this.state.view === 3 ?
                        <Container maxWidth="xs" style={{ justifyContent: "center", display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center" }}>
                          <Typography variant="h5" style={{ fontSize: "25px", fontWeight: "500", color: "#2B2B2B", fontFamily: "DINPro-Medium", cursor: "default" }}>
                            Recuperar contraseña
                          </Typography>
                          <Typography variant="h5" style={{ fontSize: "18px", fontWeight: "400", color: "#2B2B2B", fontFamily: "DINPro", marginTop: "20px", cursor: "default" }}>
                            Revisa tu correo electrónico e ingresa <br></br> el <b>código de seguridad</b> enviado.<br></br> No olvides revisar la bandeja de spam.
                          </Typography>
                          <Paper elevation={0} sx={{ borderRadius: "10px", backgroundColor: "#F9FAFB", padding: "35px 40px 35px 40px", width: "110%", marginTop: "50px" }}>
                            <Grid container justifyContent="center" spacing={0}>
                              <Grid item xs={2}>

                                <Typography variant="h5" style={{ fontSize: "40px", fontWeight: "600", marginTop: "-5px", color: "#404040", fontFamily: "DINPro-Bold", paddingRight: "10px", position: "relative" }}>
                                  K
                                  <Typography variant="h5" style={{ fontSize: "40px", fontWeight: "600", color: "#404040", fontFamily: "DINPro-Bold", position: "absolute", right: 0, top: 0 }}>
                                    -
                                  </Typography>
                                </Typography>

                              </Grid>
                              <Grid item xs={10}>
                                <Grid container justifyContent="center" spacing={1}>


                                  {[0, 1, 2, 3, 4].map((a, b) => {
                                    return (

                                      <Grid key={b} item >
                                        <TextField
                                          // placeholder="0"
                                          sx={{ height: '50px', width: '50px', backgroundColor: "#ffffff" }}
                                          inputProps={{ ...inputToken, ref: this[`tokenInput${b}`] }}
                                          id="outlined-size-small"
                                          size="small"
                                          value={this.state.forgot_token[a]}
                                          onKeyDown={(ev) => {
                                            let prevChar = this.state.forgot_token[a] || ''

                                            if (ev.key === 'Backspace' && prevChar.length < 1 && b > 0) {
                                              this[`tokenInput${b - 1}`].current.focus()
                                            }
                                          }}
                                          onChange={(ev) => {
                                            console.log('')
                                            if (ev.target.value.length === 5) {
                                              this.setState({ forgot_token: ev.target.value.split(''), showErrorToken: false })
                                              // this.setState({ forgot_token: arr, showErrorToken: false })
                                              this[`tokenInput${4}`].current.focus()
                                              return
                                            }

                                            let prevChar = this.state.forgot_token[a] || ''
                                            let char = ev.target.value[0] || ''
                                            changetoken_fp(char, a)
                                            if (prevChar.length == 0 && char.length == 0 && b > 0) {
                                              //move to previous ref

                                            }
                                            else if (char.length > 0 && b < 4) {
                                              //move to following ref
                                              console.log('move foward', this[`tokenInput${b + 1}`])
                                              // setTimeout(() => {
                                              this[`tokenInput${b + 1}`].current.focus()
                                              // },0)

                                            }

                                          }}
                                        />

                                      </Grid>
                                    )
                                  })}
                                </Grid>

                              </Grid>
                            </Grid>
                          </Paper>
                          {this.state.showErrorToken ?
                            <Typography variant="h5" style={{ fontSize: "18px", fontWeight: "400", color: "#FF0000", fontFamily: "DINPro", marginTop: "20px", cursor: "default" }}>
                              <b>El código ingresado es incorrecto.</b> <br></br> Intenta nuevamente.
                            </Typography>
                            : null}
                          <LoadingButton
                            loading={this.state.loadingtoken}
                            onClick={verifySequrityCode} disabled={this.state.forgot_token.join('').length < 5 || this.state.showErrorToken} fullWidth variant="contained" size="large" style={{ marginTop: "60px" }}>
                            Verificar
                          </LoadingButton>
                          <Button fullWidth onClick={() => {
                            this.setState({ view: this.state.view - 2 })
                            this.setState({ showErrorToken: false, forgot_token: [] })
                          }} variant="text" size="large" style={{ marginTop: "10px" }}>
                            Cancelar
                          </Button>
                        </Container>
                        :
                        this.state.view === 4 ?
                          <Container maxWidth="xs" style={{ justifyContent: "center", display: "flex", flexDirection: "column", textAlign: "center" }}>
                            <Typography variant="h5" style={{ fontSize: "25px", fontWeight: "500", color: "#2B2B2B", fontFamily: "DINPro-Medium" }}>
                              Actualizar contraseña
                            </Typography>
                            <Typography variant="h5" style={{ fontSize: "16px", fontWeight: "500", color: "#101828", fontFamily: "DINPro-Medium", textAlign: "left", marginTop: "25px" }}>
                              Nueva contraseña
                            </Typography>
                            <FormControl variant="outlined">

                              <OutlinedInput
                                placeholder="••••••••"
                                id="outlined-size-small"
                                size="small"
                                inputProps={{ ref: this.forgotPwd1Ref }}
                                onKeyDown={(event) => {
                                  if (event.key === 'Enter' && !(this.state.forgot_pwd_1.length < 8 || !(/[$,@,!,%,*,#,?,&]/.test(this.state.forgot_pwd_1)) || !(/[0-9]/.test(this.state.forgot_pwd_1)) || !(/[a-z]/.test(this.state.forgot_pwd_1)) || !(/[A-Z]/.test(this.state.forgot_pwd_1)) || this.state.forgot_pwd_1 !== this.state.forgot_pwd_2)) {
                                    // Enter key was pressed
                                    this.forgotPwd2Ref.current.focus()

                                    // Your logic for handling Enter key press
                                  }
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => { seepwd('see_forgot_pwd_1') }}
                                      // onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {this.state.see_forgot_pwd_1 ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                type={this.state.see_forgot_pwd_1 ? "text" : "password"}
                                onChange={change_recovery_pwd_1}

                              />
                            </FormControl>

                            <Typography variant="h5" style={{ fontSize: "16px", fontWeight: "500", color: "#101828", fontFamily: "DINPro-Medium", textAlign: "left", marginTop: "25px" }}>
                              Repetir contraseña
                            </Typography>


                            <FormControl variant="outlined">

                              <OutlinedInput
                                placeholder="••••••••"
                                id="outlined-size-small"
                                size="small"
                                inputProps={{ ref: this.forgotPwd2Ref }}
                                onKeyDown={(event) => {
                                  if (event.key === 'Enter' && !(this.state.forgot_pwd_1.length < 8 || !(/[$,@,!,%,*,#,?,&]/.test(this.state.forgot_pwd_1)) || !(/[0-9]/.test(this.state.forgot_pwd_1)) || !(/[a-z]/.test(this.state.forgot_pwd_1)) || !(/[A-Z]/.test(this.state.forgot_pwd_1)) || this.state.forgot_pwd_1 !== this.state.forgot_pwd_2)) {
                                    // Enter key was pressed
                                    saveNewPwd()
                                    console.log('Enter key was pressed');
                                    // Your logic for handling Enter key press
                                  }
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => { seepwd('see_forgot_pwd_2') }}
                                      // onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {this.state.see_forgot_pwd_2 ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                type={this.state.see_forgot_pwd_2 ? "text" : "password"}
                                onChange={change_recovery_pwd_2}

                              />
                            </FormControl>
                            <Paper fullWidth elevation={0} sx={{ borderRadius: "10px", backgroundColor: "#F9FAFB", padding: "30px 37px 32px 37px", marginTop: "25px" }}>
                              <Grid container >
                                <Grid item xs={6}>
                                  <Typography sx={{
                                    paddingBottom:"3px",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    color: this.state.forgot_pwd_1.length > 7 ? "#20DFB1" : "#a6a6a6",
                                    fontFamily: "DINPro-Medium",
                                    textAlign: "left",
                                    // textDecorationLine: this.state.forgot_pwd_1.length > 7 ? "line-through" : "none"
                                  }}>
                                    <CheckCircleOutlineIcon sx={{ fontSize: 15, marginRight: '5px' }} />
                                    Mínimo 8 caracteres
                                  </Typography>
                                  <Typography sx={{
                                    paddingBottom:"3px",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    color: (/[A-Z]/.test(this.state.forgot_pwd_1)) ? "#20DFB1" : "#a6a6a6",
                                    fontFamily: "DINPro-Medium",
                                    textAlign: "left",
                                    // textDecorationLine: (/[A-Z]/.test(this.state.forgot_pwd_1)) ? "line-through" : "none"
                                  }}>
                                    <CheckCircleOutlineIcon sx={{ fontSize: 15, marginRight: '5px' }} />

                                    1 mayúscula
                                  </Typography>
                                  <Typography sx={{
                                    paddingBottom:"3px",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    color: (/[a-z]/.test(this.state.forgot_pwd_1)) ? "#20DFB1" : "#a6a6a6",
                                    fontFamily: "DINPro-Medium",
                                    textAlign: "left",
                                    // textDecorationLine: (/[a-z]/.test(this.state.forgot_pwd_1)) ? "line-through" : "none"
                                  }}>
                                    <CheckCircleOutlineIcon sx={{ fontSize: 15, marginRight: '5px' }} />
                                    1 minúscula
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography sx={{
                                    fontSize: "12px",
                                    paddingBottom:"3px",

                                    fontWeight: "500",
                                    color: (/[0-9]/.test(this.state.forgot_pwd_1)) ? "#20DFB1" : "#a6a6a6",
                                    fontFamily: "DINPro-Medium",
                                    textAlign: "left",
                                    // textDecorationLine: (/[0-9]/.test(this.state.forgot_pwd_1)) ? "line-through" : "none"
                                  }}>
                                    <CheckCircleOutlineIcon sx={{ fontSize: 15, marginRight: '5px' }} />
                                    1 número
                                  </Typography>
                                  <Typography sx={{
                                    fontSize: "12px",
                                 
                                    fontWeight: "500",
                                    color: (/[$,@,!,%,*,#,?,&]/.test(this.state.forgot_pwd_1)) ? "#20DFB1" : "#a6a6a6",
                                    fontFamily: "DINPro-Medium",
                                    textAlign: "left",
                                  }}>
                                    <CheckCircleOutlineIcon sx={{ fontSize: 15, marginRight: '5px' }} />
                                    1 carácter especial
                                  </Typography>
                                  <Typography sx={{
                                    fontSize: "12px",
                                    
                                    fontWeight: "500",
                                    color: (/[$,@,!,%,*,#,?,&]/.test(this.state.forgot_pwd_1)) ? "#20DFB1" : "#a6a6a6",
                                    fontFamily: "DINPro-Medium",
                                    textAlign: "left",
                                    // textDecorationLine: (/[$,@,!,%,*,#,?,&]/.test(this.state.forgot_pwd_1)) ? "line-through" : "none"
                                  }}>
                                     <CheckCircleOutlineIcon sx={{ fontSize: 15, marginRight: '5px',visibility:"hidden" }} />
                                    ($,@,!,%,*,#,?,&)
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography sx={{
                                    fontSize: "12px",
                                    
                                    fontWeight: "500",
                                    color: (this.state.forgot_pwd_1===this.state.forgot_pwd_2 &&this.state.forgot_pwd_1 && this.state.forgot_pwd_1.length>0 ) ? "#20DFB1" : "#a6a6a6",
                                    fontFamily: "DINPro-Medium",
                                    textAlign: "left",
                                  }}>
                                    <CheckCircleOutlineIcon sx={{ fontSize: 15, marginRight: '5px' }} />
                                    Las contraseñas coinciden
                                  </Typography>
                                </Grid>
                              </Grid>


                            </Paper>
                            <LoadingButton
                              loading={this.state.loadingnewpwd} onClick={saveNewPwd} style={{ marginTop: "55px" }} disabled={this.state.forgot_pwd_1.length < 8 || !(/[$,@,!,%,*,#,?,&]/.test(this.state.forgot_pwd_1)) || !(/[0-9]/.test(this.state.forgot_pwd_1)) || !(/[a-z]/.test(this.state.forgot_pwd_1)) || !(/[A-Z]/.test(this.state.forgot_pwd_1)) || this.state.forgot_pwd_1 !== this.state.forgot_pwd_2} fullWidth variant="contained" size="large">
                              Confirmar
                            </LoadingButton>
                            <Button fullWidth variant="text" onClick={() => {
                              this.setState({ view: this.state.view - 3 })
                            }} style={{ marginTop: "10px" }} size="large">
                              Cancelar
                            </Button>
                          </Container>
                          :
                          this.state.view === 5 ?
                            <Container maxWidth="xs" style={{ justifyContent: "center", display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center", display: "flex", cursor: "default" }}>
                              <img src={ok_icon_big} style={{ width: '55px', height: "55px" }}></img>
                              <Typography variant="h5" style={{ fontSize: "35px", fontWeight: "500", color: "#2B2B2B", fontFamily: "DINPro-Medium", marginTop: "20px" }}>
                                Contraseña
                              </Typography>
                              <Typography variant="h5" style={{ fontSize: "35px", fontWeight: "500", color: "#2B2B2B", fontFamily: "DINPro-Medium", }}>
                                actualizada con éxito
                              </Typography>
                              <Typography variant="h5" style={{ fontSize: "20px", fontWeight: "400", color: "#2B2B2B", fontFamily: "DINPro", marginTop: "20px" }}>
                                Serás redireccionado al login.
                              </Typography>
                            </Container>
                            :
                            this.state.view === 7 ?
                              <Container maxWidth="xs" style={{ justifyContent: "center", display: "flex", flexDirection: "column", textAlign: "center" }}>
                                <CircularProgress />
                              </Container>
                              :
                              this.state.view === 8 ?
                                <Container maxWidth="xs" style={{ justifyContent: "center", display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center", display: "flex", cursor: "default" }}>
                                  <img src={info_icon_big} style={{ width: '55px', height: "55px" }}></img>
                                  <Typography variant="h5" style={{ fontSize: "25px", fontWeight: "500", color: "#2B2B2B", fontFamily: "DINPro-Medium", marginTop: "20px" }}>
                                    Tu usuario aún no tiene un <b>rol asignado</b> en Klarway Manager.
                                  </Typography>

                                  <Typography variant="h5" style={{ fontSize: "20px", fontWeight: "400", color: "#2B2B2B", fontFamily: "DINPro", marginTop: "20px" }}>
                                    Comunícate con la institución para acceder.
                                  </Typography>
                                </Container>
                                :
                                null
              }


            </Grid>

          </Grid>
          <GoogleReCaptcha onVerify={(token) => {
            this.recaptchatoken = token
            if (this.state.firstTime == true) {
              this.setState({ firstTime: false })
            }
          }}

          />
        </ThemeProvider>
      </div >
    );
  }
}



export default LoginPage;

